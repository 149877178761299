.card-style {
  border-radius: 20px;
  backdrop-filter: blur(14px) !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
  border-color: rgba(0, 0, 0, 0.1) !important;  
}

img {
  width: 500px;
  height: 400px;
  object-fit: cover;
  border-radius: 2rem;
}