// Define the colors
$primary-color: #007bff;
$secondary-color: #6c757d;
$success-color: #28a745;
$warning-color: #ffc107;

html {
	scroll-behavior: smooth; /* this will add smooth scroll feature */
	scroll-padding-top: 80; /* this will set offset from top, value should be similar to header height*/
}

